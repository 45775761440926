export const useMerchantGoingLiveChecklist = async () => {
	const route = useRoute();
	const config = useRuntimeConfig();
	const { $client } = useNuxtApp();
	const settings = await $client.merchant.navigation.query();
	const returnUrl = `https://${config.public.ADMIN_DASHBOARD_DOMAIN_NAME}${route.fullPath}`
	const paymentsLink =
		await $client.merchant.settings_billingPage_getStripeBillingLink.query(
			returnUrl,
		);
	let hasAtLeastOnePaymentProcessor = false;
	let hasOnOrderReceivedEvents = false;
	let hasSetupPayments = false;
	let hasSchedule = false;
	if (paymentsLink.linkType === "manage") {
		hasSetupPayments = true;
	}
	if (settings.prepaidBy) {
		hasSetupPayments = true;
	}
	if (settings.paymentMethods_enabledCryptoProcessor !== "disabled") {
		hasAtLeastOnePaymentProcessor = true;
	}
	if (settings.paymentMethods_enabledCardProcessor !== "disabled") {
		hasAtLeastOnePaymentProcessor = true;
	}
	if (settings.paymentMethods_cash_enabled !== false) {
		hasAtLeastOnePaymentProcessor = true;
	}

	hasSchedule = !(settings.schedule.regularHourRules.length === 0);
	hasOnOrderReceivedEvents = !(settings.onOrderReceivedEvents.length === 0);

	const checklistComplete =
		hasSchedule &&
		hasOnOrderReceivedEvents &&
		hasAtLeastOnePaymentProcessor &&
		hasSetupPayments;
	return {
		checklistComplete,
		checklist: {
			hasAtLeastOnePaymentProcessor,
			hasOnOrderReceivedEvents,
			hasSetupPayments,
			hasSchedule,
		},
	};
};
